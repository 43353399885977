import { FigureFileType, UploadGuideFigureFileMutation } from '../../../__generated__/graphql';
import { Unpacked } from '../../../graphQLTypes/types';
import { ImageFigureAttributes } from '../tiptap/extensions/plugins/ImageFigure';
import { Editor } from '@tiptap/core';
import { Config } from '../../../env';
import { getNodesOfType } from '../tiptap/tiptapHelper';

export type FigureFile = NonNullable<Unpacked<UploadGuideFigureFileMutation>['guideUploadFigureFile']['figureFile']>;

export interface ImageFigure {
  figureId: string;
  files: FigureFile[];
}

export const getThumbnailUrlFromImageFigure = (config: Config | undefined, guideId: string, imageFigure: ImageFigure) => {
  let file = imageFigure.files.find((file) => file.type === FigureFileType.Small);
  if (!file) {
    file = imageFigure.files.find((file) => file.type === FigureFileType.Large);
  }
  if (!file) {
    return undefined;
  }
  return getFigureFileUrl(config, guideId, imageFigure.figureId, file);
};

export const getFigureFileUrl = (config: Config | undefined, guideId: string, figureId: string, file: FigureFile) => {
  if (!config) {
    return undefined;
  }
  return `${config.apiUrl}/guides/${guideId}/figures/${figureId}/files/${file.id}/${file.fileName}?type=${file.type.toLowerCase()}`;
};

export const getImageFigures = (editor: Editor): ImageFigure[] => {
  const imageFigureNodes = getNodesOfType(editor.getJSON(), 'imageFigure');
  // Only unique figures by figureId. Do not duplicate if there are multiple nodes with the same figureId
  return imageFigureNodes
    .map((node) => mapImageFigureFromAttributes(node.attrs as ImageFigureAttributes))
    .filter((figure, index, self) => self.findIndex((f) => f.figureId === figure.figureId) === index);
};

export const mapImageFigureFromAttributes = (attrs: ImageFigureAttributes): ImageFigure => {
  return { figureId: attrs.figureId ?? '', files: getFilesFromImageFigureAttributes(attrs) };
};

export const mapToAttributesFromImageFigure = (id: string, imageFigure: ImageFigure): ImageFigureAttributes => {
  const attrs: ImageFigureAttributes = {
    id,
    figureId: imageFigure.figureId,
    smallId: null,
    smallFilename: null,
    smallMetadata: null,
    largeId: null,
    largeFilename: null,
    largeMetadata: null,
    originalId: null,
    originalFilename: null,
    originalMetadata: null,
  };
  for (const file of imageFigure.files) {
    const metadata = file.metadata
      ?.filter((m) => !!m)
      .map((m) => `${m.name}:${m.value}`)
      .join(';');
    if (file.type === FigureFileType.Small) {
      attrs.smallId = file.id;
      attrs.smallFilename = file.fileName || null;
      attrs.smallMetadata = metadata || null;
    }
    if (file.type === FigureFileType.Large) {
      attrs.largeId = file.id;
      attrs.largeFilename = file.fileName || null;
      attrs.largeMetadata = metadata || null;
    }
    if (file.type === FigureFileType.Original) {
      attrs.originalId = file.id;
      attrs.originalFilename = file.fileName || null;
      attrs.originalMetadata = metadata || null;
    }
  }
  return attrs as ImageFigureAttributes;
};

const getMetadata = (metadata: string | null): FigureFile['metadata'] => {
  const metadataParts = metadata?.split(';') || [];
  const metadataArray = metadataParts.map((part) => {
    const [name, value] = part.split(':');
    return { name, value };
  });
  return metadataArray;
};

const getFilesFromImageFigureAttributes = (attrs: ImageFigureAttributes): FigureFile[] => {
  const files: FigureFile[] = [];
  if (attrs.smallId) {
    files.push({
      id: attrs.smallId,
      figureId: attrs.figureId,
      fileName: attrs.smallFilename,
      metadata: getMetadata(attrs.smallMetadata),
      type: FigureFileType.Small,
    });
  }
  if (attrs.largeId) {
    files.push({
      id: attrs.largeId,
      figureId: attrs.figureId,
      fileName: attrs.largeFilename,
      metadata: getMetadata(attrs.largeMetadata),
      type: FigureFileType.Large,
    });
  }
  if (attrs.originalId) {
    files.push({
      id: attrs.originalId,
      figureId: attrs.figureId,
      fileName: attrs.originalFilename,
      metadata: getMetadata(attrs.originalMetadata),
      type: FigureFileType.Original,
    });
  }
  return files;
};
